import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import FAQs from "../components/Repeating/FAQs";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedLoanPrograms from "../components/Repeating/RelatedLoanPrograms";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

import downPaymentFlexibility from "../images/2.0 Loan Programs/Jumbo/Down Payment Flexibilty.svg";
import loanFlexibility from "../images/2.0 Loan Programs/Jumbo/Loan Flexibility.svg";
import moreOptions from "../images/2.0 Loan Programs/Jumbo/More Options.svg";
import competitiveInterestRates from "../images/2.0 Loan Programs/Jumbo/Competitive Interest Rates.svg";

export const query = graphql`
  query CityJumboTemplateQuery($id: String!) {
    loanProgram: sanityCityLoanPrograms(id: { eq: $id }) {
      id
      city {
        title
      }
      seoTitle
      metaDescription
      loanProgram
    }
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Jumbo Loans.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Jumbo Loans.jpg" }
    ) {
      publicURL
    }
  }
`;

const Template = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = [
    {
      question: "What housing markets do you serve?",
      answer: (
        <>
          <p className="mb-0">
            We serve eight states, with areas as diverse as{" "}
            <Link
              to="/tempe-jumbo-loans/"
              className="font-normal text-typography-body no-underline"
            >
              Tempe
            </Link>
            , Arizona,{" "}
            <Link
              to="/las-vegas-jumbo-loans/"
              className="font-normal text-typography-body no-underline"
            >
              Las Vegas
            </Link>
            , Nevada, and{" "}
            <Link
              to="/san-bernardino-jumbo-loans/"
              className="font-normal text-typography-body no-underline"
            >
              San Bernardino
            </Link>
            , California.
          </p>
        </>
      ),
    },
    {
      question: `What type of homes can I buy in ${data.loanProgram.city.title} with a jumbo loan?`,
      answer: (
        <>
          <p>You can use a jumbo loan to purchase a:</p>
          <ul className="styled-list-checkmark">
            <li>Single-family home</li>
            <li>Condominium</li>
            <li>Manufactured home</li>
            <li>Vacation home</li>
            <li>Investment property</li>
          </ul>
        </>
      ),
    },
    {
      question: "What is a conforming loan limit?",
      answer: (
        <>
          <p className="mb-0">
            Loan limits are established on a yearly basis by the FHFA and refer
            to the maximum dollar amount Fannie Mae and Freddie Mac will buy or
            guarantee. Currently the conforming loan limit for a single=family
            home is set at $647,200 and can go as high as $970,800 in certain
            high cost areas. Higher loan limits are also available for 2-4 unit
            properties as well. To find out the loan limit in your county, you
            can perform a search using the U.S. Department of Housing and Urban
            Development’s{" "}
            <a
              href="https://entp.hud.gov/idapp/html/hicostlook.cfm"
              target="_blank"
              rel="noreferrer"
            >
              online tool
            </a>
            .
          </p>
        </>
      ),
    },
    {
      question: "Are both 15-year and 30-year loans available?",
      answer: (
        <>
          <p className="mb-0">
            Yes. You can opt for the traditional 30-year loan or choose a
            15-year loan to build equity faster and take a shorter path to full
            homeownership.
          </p>
        </>
      ),
    },
    {
      question: "Can I get either a fixed-rate or adjustable-rate jumbo loan?",
      answer: (
        <>
          <p className="mb-0">
            Yes, either option is available. However, many borrowers opt for the
            adjustable-rate jumbo loan, which can offer lower interest rates
            than a fixed-rate jumbo loan.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title={data.loanProgram.seoTitle}
        description={data.loanProgram.metaDescription}
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/2.0 Loan Programs/Jumbo/1.0 jumbo-hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>{data.loanProgram.city.title} Jumbo Home Loan</h1>
              <p>
                Sometimes the price of a home will exceed the conforming loan
                limits set by the Federal Housing Finance Agency (FHFA),
                especially in competitive real estate markets. That’s where a
                jumbo loan comes in.
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <header className="mb-12 md:mb-24">
            <h2>Your Guide to Jumbo Loans</h2>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Who it’s For"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Benefits"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Eligibility"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Who It’s For</h3>
                <p className="mb-0">
                  To qualify for a jumbo loan, you’ll need a great credit score
                  and a low debt-to-income (DTI) ratio. You can buy a primary
                  residence, investment property, or vacation home if you fit
                  the requirements.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <header className="mb-10 md:mb-12">
                  <h3 className="heading-four">
                    The Benefits for {data.loanProgram.city.title} Home Buyers
                  </h3>
                </header>

                <div className="grid gap-8 md:grid-cols-2 md:gap-10">
                  <div>
                    <img
                      src={moreOptions}
                      alt="More Options"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">More Options</h3>
                    <p className="mb-0">
                      The minimum credit score you’ll need is 640, although
                      getting approved with a lower credit score is possible.
                    </p>
                  </div>

                  <div>
                    <img
                      src={downPaymentFlexibility}
                      alt="Down Payment Flexibility"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Down Payment Flexibility</h3>
                    <p className="mb-0">
                      The down payment requirement for a jumbo loan can, in some
                      instances, be as low as 5% to 15%.
                    </p>
                  </div>

                  <div>
                    <img
                      src={competitiveInterestRates}
                      alt="Competitive Interest Rates"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Competitive Interest Rates</h3>
                    <p className="mb-0">
                      Although jumbo loans can be inherently riskier for
                      lenders, interest rates are quite competitive.
                    </p>
                  </div>

                  <div>
                    <img
                      src={loanFlexibility}
                      alt="Loan Flexibility"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Loan Flexibility</h3>
                    <p className="mb-0">
                      Jumbo loans come in many different varieties and can be
                      tailored to fit the borrower and their budget.
                    </p>
                  </div>
                </div>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Eligibility Requirements</h3>
                <ul className="styled-list-checkmark">
                  <li>A strong credit score, typically 680 or higher</li>
                  <li>Low debt-to-income (DTI) ratio</li>
                  <li>High cash reserves</li>
                  <li>Proof you’re in good financial health</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FAQs heading="FAQs" uniqueFaqs={faqs} />
      <Testimonials />
      <ValueProps />
      <About />
      <RelatedLoanPrograms
        heading="Other Loan Programs"
        hiddenProgram={5}
        location={data.loanProgram.city.title}
      />
      <CallToAction />
    </Layout>
  );
};

export default Template;
